import { mapActions, mapGetters } from "vuex";

export default {
  computed: { ...mapGetters(["AUTH"]) },
  methods: {
    ...mapActions(["SHOW_OR_HIDE_MODAL_LOGIN"]),
    checkAuthForCallBack(callback) {
      if (!this.AUTH) return this.SHOW_OR_HIDE_MODAL_LOGIN(true);
      callback();
    },
  },
};
