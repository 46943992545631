<template>
  <div class="modal" :class="{ show: true }" @click.stop="$emit('close')">
    <transition name="modal">
      <div v-if="load" class="modal__content" @click.stop>
        <div class="modal__header">
          <div class="text__16 text__gray4">Получить доступ</div>
          <button class="modal__header__button" @click.prevent="$emit('close')">
            <img src="@/assets/images/svg/close.svg" alt="" />
          </button>
        </div>
        <form v-if="payForm.action" :action="payForm.action">
          <div class="modal__body">
            <input
              type="hidden"
              name="receiver"
              :value="payForm.data.hidden.receiver"
            />
            <input
              type="hidden"
              name="formcomment"
              :value="payForm.data.hidden.formcomment"
            />
            <input
              type="hidden"
              name="label"
              :value="payForm.data.hidden.label"
            />
            <input
              type="hidden"
              name="quickpay-form"
              :value="payForm.data.hidden['quickpay-form']"
            />
            <input
              type="hidden"
              name="targets"
              :value="payForm.data.hidden.target"
            />
            <input type="hidden" name="sum" :value="payForm.data.hidden.sum" />
            <input
              type="hidden"
              name="successURL"
              :value="payForm.data.hidden.successURL"
            />
            <input type="radio" name="paymentType" value="PC" />
            {{ payForm.data.radio.paymentType.PC }}
            <input type="radio" name="paymentType" value="AC" />
            {{ payForm.data.radio.paymentType.AC }}
          </div>
          <div class="modal__footer">
            <ButtonLilac type="submit" name="Отправить" />
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import ButtonLilac from "@/components/ui/button/ButtonLilac";
export default {
  name: "MPayPremium",
  components: { ButtonLilac },
  data() {
    return {
      payForm: {},
      load: false,
    };
  },
  async created() {
    this.payForm = await this.$api.pay.postPay();
  },
  mounted() {
    this.load = true;
  },
};
</script>

<style lang="scss" scoped></style>
