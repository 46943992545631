<template>
  <section class="home">
    <HBanner />
    <div class="home__winner" id="winner">
      <div class="home__winner__title text__h2 adaptive">
        <!--        Выбор победителей Вконтакте или Instagram-->
        Выбор победителей Вконтакте
        <!--        <div class="change__text">-->
        <!--          <div></div>-->
        <!--                    <div>Instagram</div>-->
        <!--        </div>-->
      </div>
      <WinnerVk />
    </div>
  </section>
</template>

<script>
import HBanner from "@/components/pages/home/HBanner";
import WinnerVk from "@/components/PickingWinnerVK.vue";

export default {
  name: "Home",
  components: { WinnerVk, HBanner },
  data() {
    return {
      textVersion: 1,
    };
  },
  mounted() {
    this.changeText();
  },
  methods: {
    changeText() {
      let max = 2;
      setInterval(() => {
        this.textVersion === max ? (this.textVersion = 1) : this.textVersion++;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__winner {
    width: 100%;
    max-width: 600px;
    margin: 50px auto;
    &__title {
      margin: 0 0 24px;
      display: flex;
    }
  }
}

.change__text {
  height: 30px;
  display: block;
  overflow: hidden;
  padding: 0 0 0 8px;
  div:first-child {
    list-style: none;
    animation: tween 3s infinite;
  }
  div {
    height: 30px;
  }
}

@keyframes tween {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: -30px;
  }
  100% {
    margin-top: 0;
  }
}
</style>
