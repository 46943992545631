<template>
  <div>
    <div class="winner__link">
      <Form
        class="winner__link__item"
        :validation-schema="formWin"
        @submit="checkWin"
      >
        <div>
          <IBase
            :label="true"
            name="link"
            type="text"
            @eventInput="(val) => (formWinMain.link = val)"
            placeholder="Вставьте ссылку"
          />
          <div style="margin: 24px 0 0">
            <div class="accounts__exit">
              <div
                class="button__auth__vk"
                style="line-height: 24px"
                v-if="AUTH && !USER_VK?.id"
              >
                <!--                v-if="AUTH && !USER_IG?.id && !USER_VK?.id"-->
                теперь необходимо авторизирвоаться
                <span class="button__vk" @click="getLinkVK">ВКонтакте</span>
                <!--                или
                в <span class="button__ig" @click="getLinkIG">Instagram</span>-->
              </div>
            </div>
            <div class="accounts__exit">
              <div v-if="AUTH && USER_VK?.id" class="account__vk">
                <img :src="USER_VK.photo_200_orig" alt="" />
                <span class="text__12"
                  >{{ USER_VK.first_name }} {{ USER_VK.last_name }}</span
                >
                <div
                  class="button__vk"
                  style="margin: 0 0 0 4px"
                  @click="logoutVK"
                >
                  Выход
                </div>
              </div>
              <!--              <div v-if="AUTH && USER_IG?.id" class="account__ig">
                <img :src="imageIgBase64(USER_IG.image_base64)" alt="avatar" />
                <span class="text__12">{{ USER_IG.userName }}</span>
                <div
                  class="button__ig"
                  style="margin: 0 0 0 4px"
                  @click="logoutIG"
                >
                  Выход
                </div>
              </div>-->
            </div>
          </div>
        </div>
        <ButtonLilac name="Старт" :disabled="waitResponseGeneratorVK" />
      </Form>
      <div class="winner__link__item">
        <div class="setup__input">
          <div class="setup__input__radio">
            <div class="setup__input__title text__16 adaptive">Выбрать из:</div>
            <IRadio label="Лайков" value="like" v-model="formWinMain.option" />
            <IRadio
              label="Репостов"
              value="repost"
              v-model="formWinMain.option"
            />
            <IRadio
              label="Комментариев"
              value="comment"
              v-model="formWinMain.option"
            />
            <!--          <IRadio label="Авторов" value="author" v-model="formWinMain.option" />-->
          </div>
          <div class="setup__input__checkbox">
            <div class="setup__input__title text__16 adaptive">
              <span class="text__purple">PREMIUM</span> опции
            </div>
            <ICheckbox
              label="Проверка подписки"
              value="subscribe"
              v-model="formWinMain.extra_option_list"
            />
            <ICheckbox
              label="Проверка лайка"
              value="like"
              v-model="formWinMain.extra_option_list"
            />
          </div>
        </div>
        <ButtonLilac
          name="Купить PREMIUM"
          style="white-space: nowrap"
          @click="checkAuthForCallBack(() => (this.showPay = true))"
        />
      </div>
    </div>
    <Loader v-if="waitResponseGeneratorVK" />
    <div
      v-if="!waitResponseGeneratorVK && winners?.checked_profiles?.length"
      class="winner__result"
    >
      <div class="winner__result__title text__h2 adaptive">Победитель</div>
      <div v-if="vk" class="winner__result__profiles">
        <a
          v-for="prof in winners.checked_profiles"
          :key="prof.id"
          :href="`https://vk.com/${prof.profile.screen_name}`"
          target="_blank"
        >
          <div class="checked__profiles" v-if="prof.profile">
            <div class="checked__profiles__avatar">
              <img :src="prof.profile.photo_200_orig" alt="" />
            </div>
            <div class="checked__profiles__info text__16 adaptive text__black">
              <span
                >{{ prof.profile.first_name }}
                {{ prof.profile.last_name }}</span
              >
              <div
                v-if="USER?.premium && clickShowPremium"
                class="text__14 adaptive text__gray3"
              >
                <div v-if="checkExtra('like')">
                  Лайк: {{ prof.options.like ? "да" : "нет" }}
                </div>
                <div v-if="checkExtra('subscribe')">
                  Подписка: {{ prof.options.subscribe ? "да" : "нет" }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <!--      <div v-else class="winner__result__profiles">
        <a
          v-for="prof in winners.checked_profiles"
          :key="prof.id"
          :href="`https://instagram.com/${prof.profile.userName}`"
          target="_blank"
        >
          <div class="checked__profiles" v-if="prof.profile">
            <div class="checked__profiles__avatar">
              <img :src="imageIgBase64(prof.profile.image_base64)" alt="" />
            </div>
            <div class="checked__profiles__info text__16">
              <span>{{ prof.profile.userName }}</span>
              <div v-if="USER.premium && clickShowPremium">
                <div v-if="checkExtra('like')">
                  Лайк: {{ prof.options.like ? "да" : "нет" }}
                </div>
                <div v-if="checkExtra('subscribe')">
                  Подписка: {{ prof.options.subscribe ? "да" : "нет" }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>-->
    </div>

    <MPayPremium v-if="showPay" @close="showPay = false" />
  </div>
</template>

<script>
import checkAuthForCallBack from "@/mixins/checkAuthForCallBack";
import ButtonLilac from "@/components/ui/button/ButtonLilac";
import IRadio from "@/components/ui/input/IRadio";
import IBase from "@/components/ui/input/IBase";
import ICheckbox from "@/components/ui/input/ICheckbox";
import MPayPremium from "@/components/ui/modal/MPayPremium";
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
  // name: "WinnerVk",
  name: "PickingWinnerVK",
  mixins: [checkAuthForCallBack],
  components: { Loader, MPayPremium, ButtonLilac, IRadio, IBase, ICheckbox },
  data() {
    const formWin = this.$yup.object().shape({
      link: this.$yup.string().required(),
    });
    return {
      showPay: false,
      formWin,
      formWinMain: {
        option: "like",
        type_post: "",
        ig_account: "",
        extra_option_list: [],
      },
      winners: {},
      waitResponseGeneratorVK: false,
      clickShowPremium: false,
      instagram: false,
      vk: false,
    };
  },
  watch: {
    "formWinMain.extra_option_list"() {
      this.clickShowPremium = false;
    },
  },
  computed: {
    ...mapGetters(["USER_VK", "USER_IG", "USER"]),
  },
  methods: {
    ...mapActions(["GET_USER_ME_VK", "GET_USER_ME_IG"]),
    imageIgBase64(image) {
      return `data:image/png;base64, ${image}`;
    },
    checkExtra(string) {
      return this.formWinMain.extra_option_list.find((item) => item === string);
    },
    checkLinkVk() {
      return (
        !!this.formWinMain.link && this.formWinMain.link.includes("vk.com")
      );
    },
    checkLinkInstagram() {
      return (
        !!this.formWinMain.link &&
        this.formWinMain.link.includes("instagram.com")
      );
    },
    async getLinkVK() {
      const data = await this.$api.auth.postAuthLinkVk();
      window.open(data, "_self");
    },
    async getLinkIG() {
      const data = await this.$api.auth.postAuthLinkIG();
      window.open(data, "_self");
    },
    checkWin({ link }) {
      this.checkAuthForCallBack(async () => {
        let data;
        if (
          ((this.clickShowPremium = !0),
          (this.winners = null),
          (this.waitResponseGenerator = !0),
          (this.instagram = !1),
          (this.vk = !1),
          link.includes("instagram.com"))
        ) {
          this.instagram = !0;
          try {
            (data = await this.$api.generator.postGeneratorIg({
              link: link,
              option: this.formWinMain.option,
              extra_option_list: this.formWinMain.extra_option_list,
            })),
              console.log(data);
          } catch (c) {
            console.log(c);
          }
        } else if (link.includes("vk.com")) {
          let i;
          if (
            "WALL" !== this.formWinMain.type_post &&
            "GROUP" !== this.formWinMain.type_post
          )
            return (
              this.$toast("Выберите тип поста."),
              void (this.waitResponseGenerator = !1)
            );
          null !== (i = this.USER) && void 0 !== i && i.vk_id
            ? ((this.vk = !0),
              (data = await this.$api.generator.postGeneratorVk({
                link: link,
                option: this.formWinMain.option,
                extra_option_list: this.formWinMain.extra_option_list,
                type_post: this.formWinMain.type_post,
              })))
            : this.$toast(
                "Для проведения розыгрыша в VK необходима авторизация."
              );
        } else
          this.$toast(
            "Проверьте правильность указанной ссылки и повторите попытку."
          );
        let a;
        if (this.vk || this.instagram)
          if (
            (data && (this.winners = data),
            1 === data.code && 401 === data.status)
          )
            this.$toast("Необходима авторизация.");
          else if (this.vk && 212 === data.code)
            this.$toast("Страница должна быть открыта");
          else if (0 === data.code) this.$toast(data.message);
          else if (null !== (a = data) && void 0 !== a && a.length)
            for (let e of data) this.$toast(e.message);
        this.waitResponseGenerator = !1;
      });
    },
    async logoutVK() {
      const e = await this.$api.auth.logoutVk();
      e && (await this.GET_USER_ME(), await this.GET_USER_ME_VK());
    },
    async logoutIG() {
      const e = await this.$api.auth.logoutIg();
      e && (await this.GET_USER_ME(), await this.GET_USER_ME_IG());
    },
    /*checkWin({ link }) {
      this.checkAuthForCallBack(async () => {
        this.clickShowPremium = true;
        this.winners = {};
        this.waitResponseGeneratorVK = true;
        let data;
        if (link.includes("instagram")) {
          this.vk = false;
          data = await this.$api.generator.postGeneratorIg({
            link,
            option: this.formWinMain.option,
            extra_option_list: this.formWinMain.extra_option_list,
          });
        } else {
          this.vk = true;
          data = await this.$api.generator.postGeneratorVk({
            link,
            option: this.formWinMain.option,
            extra_option_list: this.formWinMain.extra_option_list,
          });
        }

        this.waitResponseGeneratorVK = false;

        if (data.checked_profiles) {
          this.winners = data;
        }

        if (data.code === 1 && !this.USER?.vk_id)
          this.$toast("Необходима авторизация VK или попробуйте еще раз");

        if (data.code === 1 && data.status === 401 && this.USER.vk_id) {
          await this.checkWin({ link });
        }

        if (data.code === 0) {
          this.$toast(data.message);
        }

        if (data?.length) {
          for (let item of data) {
            this.$toast(item.message);
          }
        }
      });
    },*/
    /*async logoutVK() {
      const data = await this.$api.auth.logoutVk();
      if (data) await this.GET_USER_ME_VK();
    },
    async logoutIG() {
      const data = await this.$api.auth.logoutIg();
      if (data) await this.GET_USER_ME_IG();
    },*/
  },
};
</script>

<style lang="scss" scoped>
.winner__link {
  max-width: 600px;
  width: 100%;

  .winner__link__item {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 12px;
    align-items: start;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    &:first-child {
      margin: 0 0 12px;
    }
  }

  label {
    width: 100%;
  }
  .setup__input {
    @include disflex(flex, stretch, flex-start);
    text-align: left;
    gap: 30px;

    &__title {
      margin: 0 0 12px;
    }
    &__checkbox {
      display: flex;
      flex-direction: column;
    }
    &__radio,
    &__checkbox {
      label {
        margin: 0 0 4px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.account__vk,
.account__ig {
  @include disflex(flex, stretch, center);
  img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 4px 0 0;
  }
}

.button__auth__vk {
}

.button__vk,
.button__ig {
  position: relative;
  border-radius: 3px;
  padding: 2px 5px 2px 4px;
  color: #fff;
  cursor: pointer;
}

.button__vk {
  background: rgb(0, 119, 255);
}

.button__ig {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
}

.winner__result {
  margin: 20px 0 0;
  &__title {
    margin: 0 0 16px;
  }
  &__profiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    justify-items: center;
  }
}

.checked__profiles {
  @include disflex(flex, stretch, center);
  &__avatar {
    flex: 0 1 100px;
    margin: 0 8px 0 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }
  &__info {
    span {
      display: block;
    }
  }
}

.accounts__exit {
  div:last-child,
  button:last-child {
    margin: 10px 0 0;
  }
}
</style>
