<template>
  <label class="custom__checkbox">
    <input
      type="checkbox"
      :value="value"
      :checked="modelValue.includes(value)"
      @change="(evt) => onChange(evt.target.value)"
    />
    <span class="custom__icon" :class="borderClass">
      <img
        v-show="modelValue.includes(value)"
        src="@/assets/images/svg/checked.svg"
        alt="checked"
      />
    </span>
    <span v-if="label" class="text__14" :class="textClass">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "ICheckbox",
  props: {
    label: String,
    value: { type: String, default: null },
    modelValue: { type: Array, default: () => [] },
  },
  computed: {
    textClass() {
      return this.modelValue.includes(this.value)
        ? "text__liliac"
        : "text__gray4";
    },
    borderClass() {
      return this.modelValue.includes(this.value) ? "active" : "";
    },
  },
  methods: {
    onChange(value) {
      if (this.modelValue.includes(this.value)) {
        this.$emit(
          "update:modelValue",
          this.modelValue.filter((cv) => cv !== value)
        );
      } else {
        this.$emit("update:modelValue", this.modelValue.concat(value));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom__checkbox {
  @include disflex(flex, stretch, center);
  white-space: nowrap;
}
.custom__icon {
  position: relative;
  margin: 0 12px 0 0;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 1px solid $gray2;
  border-radius: 4px;
  @include disflex(flex, center, center);
  flex: 0 0 18px;
  img {
    width: 9px;
    height: 7px;
  }
  &.active {
    border: 1px solid $lilac;
  }
}

input {
  display: none;
}
</style>
