<template>
  <div class="home__banner">
    <div class="home__banner__text">
      <div class="home__banner__title text__h1 adaptive text__black">
        Выбор победителей Вконтакте или Instagram
      </div>
      <div class="home__banner__subtitle text__18 adaptive text__black">
        Наша цель - помочь провести розыгрыш быстро и эффективно
      </div>
      <ButtonPurple
        name="Определить победителя"
        @click="
          checkAuthForCallBack(() =>
            $router.push({ name: 'Home', hash: '#winner' })
          )
        "
      />
    </div>
    <div class="home__banner__images">
      <div class="home__banner__slider">
        <div v-for="item in 10" :key="item.id">
          <img :src="require(`@/assets/images/promo/${item}.jpg`)" alt="" />
        </div>
      </div>
      <div class="home__banner__info text__16 adaptive">
        <div>
          Всего обработано:
          <span class="text__gray3">{{ formatted(number) }}</span>
        </div>
        <div>
          комментария в
          <span class="text__gray3">{{ formatted(number2) }} розыгрышей</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkAuthForCallBack from "@/mixins/checkAuthForCallBack";

export default {
  name: "Banner",
  mixins: [checkAuthForCallBack],
  data() {
    return {
      number: 0,
      number2: 0,
    };
  },
  methods: {
    formatted(string) {
      return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  mounted() {
    this.number = String(Date.parse(new Date())).substring(1, 10);
    this.number2 = String(Date.parse(new Date()) * 23 + 3222).substring(6, 10);
  },
};
</script>

<style lang="scss" scoped>
.home__banner {
  border-radius: 12px;
  padding: 100px 0 100px;
  @include disflex(flex, space-between, center);
  @media screen and (max-width: 976px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1920px) {
    @include adaptive-value("padding", 100 20, 0, 100 20, 0);
  }
  gap: 80px;
  &__subtitle {
    margin: 24px 0;
  }

  &__text {
    flex: 0 1 40%;
  }

  &__images {
    flex: 0 1 60%;
    .home__banner__slider {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__info {
    margin: 24px 0 0;
    @include disflex(flex, stretch, center);
    gap: 8px;
    @media screen and (max-width: 568px) {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
</style>
