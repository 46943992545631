<template>
  <label class="custom__radio">
    <input
      type="radio"
      :value="value"
      @change="$emit('update:modelValue', $event.target.value)"
      :checked="value == modelValue"
    />
    <span class="custom__radio__input" />
    <span
      v-if="label"
      class="text__14"
      :class="{
        text__gray3: value != modelValue,
        text__gray4: value == modelValue,
      }"
      >{{ label }}</span
    >
  </label>
</template>

<script>
export default {
  name: "IRadio",
  emits: ["update:modelValue"],
  props: {
    label: String,
    value: { type: [String, Number], default: null },
    modelValue: { type: [String, Number], default: null },
  },
};
</script>

<style lang="scss" scoped>
.custom__radio {
  @include disflex(flex, stretch, center);
  cursor: pointer;

  span,
  .custom__radio__input:after {
    transition: 0.2s ease-in-out;
  }

  &__input {
    position: relative;
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid #b5b6d2;
    border-radius: 9px;
    margin: 0 12px 0 0;
    flex: 0 1 18px;
    @include disflex(flex, center, center);

    &:after {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      background: $lilac;
      border-radius: 9px;
      opacity: 0;
      transform: scale(0);
    }
  }

  input {
    display: none;
    &:checked + .custom__radio__input {
      border: 1px solid $lilac;
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
</style>
